
































































































































































import Vue from 'vue';
import Card from '@/components/Cards/CardComponent.vue';
import CommonFilters from '@/components/Filters/CommonFiltersComponent.vue';

import { MarketInsightsComponent } from './MarketInsights';
import { filtersData } from './marketInsights.config';
import ButtonDefault from '@/components/Buttons/ButtonDefault.vue';
import { GetMarketInsightsResponse } from '@/store/store';

export default (Vue as MarketInsightsComponent).extend({
  beforeMount() {
    if (this.$route.params.language) {
      this.$store.state.selectedLanguage = this.languageCodes[this.$route.params.language];
    }
  },
  components: {
    Card,
    CommonFilters,
    ButtonDefault,
  },
  data() {
    return {
      filters: '',
      isLoading: true,
      cardData: [],
      filtersData: null,
      orderEstimation: null,
      urls: {
        pt: 'https://form.typeform.com/to/NrPlgB8G',
        es: 'https://form.typeform.com/to/U80Iieia',
        en: 'https://form.typeform.com/to/G747lp7h',
      },
      languageCodes: {
        pt: 'pt-PT',
        es: 'es-ES',
        en: 'en-GB',
      },
    };
  },
  async mounted() {
    await this.$store.dispatch('getCityAndPlatforms');

    const cityAndPlatforms = this.$store.getters.getCityAndPlatforms;

    this.filtersData = filtersData(
      cityAndPlatforms.cities,
      cityAndPlatforms.platforms,
      cityAndPlatforms.defaultCity
    );
  },
  computed: {
    currentPlatform() {
      const currentPlatformIndex = this.currentFilters.findIndex((item) =>
        item.includes('platform:')
      );

      if (currentPlatformIndex > -1) {
        return this.currentFilters[currentPlatformIndex].split('platform:')[1];
      }

      return '';
    },
    currentCity() {
      const currentPlatformIndex = this.currentFilters.findIndex((item) => item.includes('city:'));

      if (currentPlatformIndex > -1) {
        return this.currentFilters[currentPlatformIndex].split('city:')[1];
      }

      return '';
    },
    currentFilters() {
      return this.filters.split(';');
    },
  },
  methods: {
    handleClickFindOutMore() {
      const languageCode = this.$route.params.language || this.$store.state.selectedLanguage;
      window.open(this.urls[languageCode] || this.urls.en, '_blank');
    },
    isPositive(value) {
      return value >= 0;
    },
    getVariation(value) {
      return this.isPositive(value) ? '+' + value.toFixed(0) : value.toFixed(0);
    },
    handleOnFilter(filters) {
      this.filters = filters.filtersStr;
      this.getData();
    },
    async getData() {
      this.isLoading = true;

      this.$store
        .dispatch('getMarketInsights', this.filters)
        .then((response: GetMarketInsightsResponse) => {
          this.cardData = response.cardPeriods;
          this.orderEstimation = response.sampleSize;
        })
        .catch(() => this.$toast.error(this.$t('errors.marketTrends')))
        .finally(() => (this.isLoading = false));
    },
  },
});
