<template>
  <main class="content">
    <div class="content__body">
      <router-view class="content__authenticated-layout" />
    </div>
  </main>
</template>

<script>
export default {
  name: 'BlankLayout',
};
</script>
