<template>
  <div>
    <TermsAndConditionsComponent
      v-if="showTermsAndConditionModal"
      :terms-and-conditions="termsAndConditions"
      :is-accepted="isTermsAccepter"
      @closeClick="handleCloseClick"
      @termsAgreed="handleTermsAgreed"
    />
    <template v-if="isTermsAccepter">
      <Header @menuClick="handleMobileMenuClick" />

      <main class="content">
        <Sidebar
          :shouldShowOverlay="!isDesktop"
          :shouldShowSideBar="shouldShowSideBar"
          @close="handleMobileOverlayClick"
        />

        <div class="content__body">
          <Breadcrumb class="content__bread-crumbs" />
          <router-view class="content__authenticated-layout" />
          <Footer
            class="content__footer"
            @termsAndConditionsClick="showTermsAndConditionModal = true"
          />
        </div>
      </main>
    </template>
  </div>
</template>

<script>
import Header from '@/components/Header/HeaderComponent.vue';
import Footer from '@/components/Footer/FooterComponent.vue';
import Sidebar from '@/components/Sidebar/SidebarComponent';
import Breadcrumb from '@/components/BreadCrumb/BreadCrumbComponent.vue';
import TermsAndConditionsComponent from '@/components/TermsAndConditions/TermsAndConditionsComponent.vue';

import resizeMixin from '@/mixins/resize';
import { viewPort } from '@/utils/constants';

export default {
  name: 'AuthenticatedLayout',
  components: {
    Header,
    Footer,
    Breadcrumb,
    Sidebar,
    TermsAndConditionsComponent,
  },
  mixins: [resizeMixin],
  beforeMount() {
    if (!this.$session.getSession('pleez-token')) {
      this.$router.push('/');
    }

    window.addEventListener('resize', () => (this.isSideBarVisible = false));
  },
  async mounted() {
    // this.termsAndConditions = await this.$store.dispatch('getTermsAndConditions');
    // if (!this.termsAndConditions) {
    //   throw new Error('Could not get terms and conditions.');
    // }
    // if (this.termsAndConditions.acceptedTerms) {
    //   return;
    // }
    // this.showTermsAndConditionModal = true;
  },
  data() {
    return {
      isSideBarVisible: false,
      showTermsAndConditionModal: false,
      termsAndConditions: null,
    };
  },
  computed: {
    shouldShowSideBar() {
      return this.isDesktop || this.isSideBarVisible;
    },
    isDesktop() {
      return this.currentViewPort === viewPort.Desktop;
    },
    isTermsAccepter() {
      return Boolean(this.termsAndConditions?.acceptedTerms);
    },
  },
  methods: {
    handleMobileMenuClick() {
      this.isSideBarVisible = !this.isSideBarVisible;
    },
    handleMobileOverlayClick() {
      this.isSideBarVisible = false;
    },
    handleCloseClick() {
      this.showTermsAndConditionModal = false;
    },
    handleTermsAgreed() {
      this.showTermsAndConditionModal = false;
      this.termsAndConditions.acceptedTerms = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  --max-content-size: 2000px;
}

.content {
  display: flex;
  flex-direction: column;

  &__body {
    display: flex;
    flex-direction: column;

    flex: 1;

    padding: 0 var(--spacing-s) var(--spacing-s);

    background-color: var(--grey-background);
  }
  &__authenticated-layout,
  &__bread-crumbs {
    max-width: var(--max-content-size);
  }
  &__authenticated-layout {
    width: 100%;
    margin: 0 auto;
  }
  &__footer {
    margin-top: auto;
  }

  @include breakpoint-from('smallDesktop') {
    flex-direction: row;
    flex: 1;

    overflow: auto;

    &__body {
      overflow: auto;
    }
  }
}
</style>
