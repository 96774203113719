import { render, staticRenderFns } from "./FiltersComponent.vue?vue&type=template&id=c77707e4&"
import script from "./FiltersComponent.vue?vue&type=script&lang=ts&"
export * from "./FiltersComponent.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./FiltersComponent.vue?vue&type=custom&index=0&blockType=styles&scoped=true&lang=scss"
if (typeof block0 === 'function') block0(component)

export default component.exports