var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'select',
    {
      'select--with-divider': _vm.hasDivider,
      'select--checkmark': _vm.hasCheckmark,
      'select--with-select-all': _vm.hasSelectAll,
    } ]},[_c('label',{class:[_vm.isLabelVisible ? 'select__label' : 'select__visually-hidden'],attrs:{"for":(_vm.label + "-select")}},[_vm._v(_vm._s((_vm.label + ":"))+" ")]),_c('div',{staticClass:"select__wrapper"},[_c('span',{staticClass:"select__icon",attrs:{"aria-hidden":""}},[_vm._t("default")],2),_c('v-select',{class:[
        'select__element',
        {
          'select__element--with-icon': _vm.hasSelectIcon,
        } ],attrs:{"input-id":(_vm.label + "-select"),"options":_vm.paginated,"placeholder":_vm.selectPlaceHolder,"label":_vm.displayKey,"getOptionKey":_vm.generateFilterKey,"multiple":_vm.multiple,"autoscroll":!_vm.multiple,"deselectFromDropdown":_vm.multiple,"closeOnSelect":!_vm.multiple,"clearable":_vm.isClearable,"taggable":_vm.allowNewOptions,"disabled":_vm.disabled,"searchable":_vm.hasSearch,"loading":_vm.isLoading,"filter":_vm.searchParser},on:{"open":_vm.handleOnOpen,"option:selecting":_vm.handleOptionSelecting,"option:selected":_vm.handleOptionSelected,"option:deselected":_vm.handleOptionDeselected,"close":_vm.handleOnClose,"search":_vm.handleSearch,"option:created":_vm.handleNewOption},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{staticClass:"select__option-wrapper",class:{
            'select__option-wrapper--multiline': _vm.customBodyIsMultiLines || _vm.multiple,
          }},[(_vm.multiple)?_c('div',{staticClass:"select__multiple"},[(_vm.multiple)?_c('CheckBox'):_vm._e()],1):_vm._e(),(_vm.hasCustomLabel)?_vm._t("custom-label",null,{"value":option}):_vm._e(),(option[_vm.displayKey ? _vm.displayKey : _vm.label] && !_vm.hasCustomLabel)?_c('p',{staticClass:"select__default-label"},[_vm._v(" "+_vm._s(option[_vm.displayKey ? _vm.displayKey : _vm.label])+" ")]):_vm._e()],2)]}},{key:"selected-option",fn:function(option){return [_vm._t("custom-selected-option",null,{"value":option})]}},{key:"selected-option-container",fn:function(ref){
          var option = ref.option;
          var multiple = ref.multiple;
return [(multiple)?_c('div',[(_vm.isOptionAtIndex(option[_vm.displayKey || 'label'], 0))?[_vm._v(" "+_vm._s(_vm.visuallySelectedOption(option))+" ")]:_vm._e()],2):_vm._e()]}},{key:"list-footer",fn:function(){return [_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasNextPage),expression:"hasNextPage"}],ref:"load",staticClass:"select__element--loading"},[_vm._v(" "+_vm._s(_vm.$translations.shared.inputs.lazyListHelper[_vm.currentLanguage])+" ")]),_vm._t("custom-footer")]},proxy:true},{key:"no-options",fn:function(){return [(!_vm.hasCustomFooter)?_c('li',[_vm._v(" "+_vm._s(_vm.$translations.shared.inputs.noItems[_vm.currentLanguage])+" ")]):_vm._e(),_c('li',[_vm._t("custom-no-options")],2)]},proxy:true}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),(_vm.multiple)?_c('span',{staticClass:"select__multiple-selection-count"},[_vm._v(" "+_vm._s(_vm.multipleSelectionCount)+" ")]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }